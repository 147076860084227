<template>
  <el-row v-loading.fullscreen="!companyReady"
    :element-loading-text="$t('general.loading')"
    tag="section"
    class="p-20px api-keys-content"
    :gutter="15">
    <el-col :xs="24"
      :sm="6"
      :md="4"
      class="mb-2">
      <el-card shadow="never">
        <mu-mega-icon icon="API"
          class="api-icon" />
        <p class="mt-0">
          {{ $t('api-keys.description-intro') }}
        </p>
        <i18n path="api-keys.description-html"
          tag="p">
          <template #environment>
            <strong>{{ environmentName }}</strong>
          </template>
        </i18n>
        <i18n path="api-keys.learn-more-html"
          tag="p">
          <template #link>
            <a href="https://dev.megaport.com"
              target="_blank">{{ $t('api-keys.api-documentation') }}</a>
          </template>
        </i18n>
      </el-card>
    </el-col>
    <el-col :xs="24"
      :sm="18"
      :md="20">
      <template v-if="!hasAdminPrivileges">
        <el-card shadow="never">
          <h2>{{ $t('api-keys.api-keys') }}</h2>
          <p>{{ $t('api-keys.forbidden') }}</p>
        </el-card>
      </template>
      <template v-else>
        <generate-api-keys />
        <active-api-keys />
      </template>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GenerateApiKeys from './GenerateApiKeys.vue'
import ActiveApiKeys from './ActiveApiKeys.vue'
import { ROLE_TYPE_COMPANY_ADMIN } from '@/Globals'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  name: 'ApiKeysPage',

  components: {
    'generate-api-keys': GenerateApiKeys,
    'active-api-keys': ActiveApiKeys,
  },

  computed: {
    ...mapState({
      companyReady: state => state.Company.companyReady,
    }),
    ...mapState('UserProfile', ['profile']),
    ...mapGetters('Auth', ['hasAuth']),
    hasAdminPrivileges() {
      return this.hasAuth('company_admin') || [ROLE_TYPE_COMPANY_ADMIN].includes(this.profile.position)
    },
    environmentName() {
      return this.$appConfiguration.environmentName
    },
  },

  mounted() {
    // Redirect back to services page if not company/technical admin
    // This was placed here as user profile position could not be read through route guard
    if (!this.hasAdminPrivileges) {
      this.$router.push(resolveServicesPage(), () => {})
    }
  },
}
</script>

<style scoped>
::v-deep .api-icon {
  width: 40px;
  height: auto;
  color: var(--color-text-regular);
}
.el-row {
  margin: 0 !important;
}
</style>
