const roleOptions = component => {
  return [
    {
      option: 'companyAdmin',
      label: component.$t('api-keys.admin'),
    },
    {
      option: 'readOnlyUser',
      label: component.$t('api-keys.read-only-short'),
    },
  ]
}

const sanitiseRole = (component, role) => {
  switch (role) {
    case 'readOnlyUser':
      return component.$t('api-keys.read-only-short')
    case 'companyAdmin':
      return component.$t('api-keys.admin')
    case 'api':
      return component.$t('api-keys.api-role')
    default:
      return component.$t('api-keys.unknown-role')
  }
}


export { roleOptions, sanitiseRole }
