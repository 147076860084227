<template>
  <el-card v-loading="generatingKey"
    shadow="never"
    class="mb-2"
    :element-loading-text="$t('api-keys.generating')">
    <key-display-dialog :visible.sync="keyDialogVisible"
      :api-key="generatedKey.apiKey"
      :api-secret="generatedKey.apiSecret" />

    <h2>{{ $t('api-keys.api-keys') }}</h2>
    <p>{{ $t('api-keys.key-description') }}</p>
    <h4>{{ $t('api-keys.generate-label') }}</h4>

    <p v-if="isLoggedInAs">
      {{ $t('api-keys.read-only-impersonate') }}
    </p>

    <el-form v-else
      ref="generateApiKeysForm"
      :model="generateApiKeysForm"
      :rules="generateApiKeysRules"
      :inline="true"
      label-position="top">
      <el-form-item prop="name"
        class="name-label">
        <template #label>
          {{ $t('general.name') }}
          <el-tooltip placement="top">
            <span slot="content">
              <ul class="pl-2 my-0">
                <li>{{ $t('api-keys.name-min') }}</li>
                <li>{{ $t('api-keys.name-max') }}</li>
                <li>{{ $t('api-keys.name-chars') }}</li>
              </ul>
            </span>
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="generateApiKeysForm.name"
          name="name"
          :placeholder="$t('general.name')"
          data-name="name"
          data-demo="Example API Key"
          data-testid="name" />
      </el-form-item>
      <el-form-item prop="role"
        class="role-label"
        :label="$t('api-keys.role')">
        <el-select v-model="generateApiKeysForm.role"
          :placeholder="$t('general.please-select')"
          name="role"
          data-name="role"
          data-testid="role">
          <el-option v-for="option in roleOptions"
            :key="option.option"
            :label="option.label"
            :value="option.option" />
        </el-select>
      </el-form-item>
      <el-form-item prop="expiry"
        class="expiry-label long-error-de long-error-ja">
        <template #label>
          {{ $t('api-keys.token-expiry') }}
          <el-tooltip placement="top">
            <span slot="content">
              <ul class="pl-2 my-0">
                <li>{{ $t('api-keys.expiry-min') }}</li>
                <li>{{ $t('api-keys.expiry-max') }}</li>
              </ul>
            </span>
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="generateApiKeysForm.expiry"
          type="number"
          min="5"
          max="1440"
          name="expiry"
          data-testid="expiry" />
      </el-form-item>
      <br>
      <el-button type="primary"
        data-name="generateKey"
        data-testid="generateKey"
        @click="generateKey">
        {{ $t('api-keys.generate-key') }}
      </el-button>
    </el-form>
  </el-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import captureSentryError from '@/utils/CaptureSentryError.js'
import KeyAndSecretDialog from '@/components/api-keys/KeyAndSecretDialog.vue'
import { roleOptions } from '@/components/api-keys/ApiKeyUtils.js'
import sdk from '@megaport/api-sdk'

const EXPIRY_DEFAULT = 1440

export default {
  name: 'GenerateApiKeys',

  components: {
    'key-display-dialog': KeyAndSecretDialog,
  },

  data() {
    return {
      generateApiKeysForm: {
        name: '',
        role: '',
        expiry: EXPIRY_DEFAULT,
      },
      generateApiKeysRules: {
        name: { required: true, min: 3, max: 90, pattern: /^[\w -]{3,90}$/, message: this.$t('api-keys.name-invalid', { thing: 'Name' }), trigger: 'blur' },
        role: { required: true, message: this.$t('validations.required', { thing: 'Role' }), trigger: 'blur' },
        expiry: { required: false, validator: this.validateTokenExpiry, trigger: 'blur' },
      },
      generatingKey: false,
      keyDialogVisible: false,
      generatedKey: {
        apiKey: '',
        apiSecret: '',
      },
    }
  },

  computed: {
    ...mapGetters('Auth', ['isLoggedInAs']),
    roleOptions() {
      return roleOptions(this)
    },
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    /**
     * Validate token expiry to be between 5 - 1440 minutes (24 hours)
     * @param {Object} rule
     * @param {string} value
     * @param {Function} callback
     */
    validateTokenExpiry(_rule, value, callback) {
      if (value < 5 || value > 1440) {
        callback(this.$t('validations.api-keys-expiry'))
      } else if (value % 1 !== 0) {
        callback(this.$t('validations.api-keys-expiry-decimal'))
      } else {
        callback()
      }
    },
    /**
     * Generate API key from form data
     */
    generateKey() {
      this.$refs['generateApiKeysForm'].validate(valid => {
        if (!valid) return

        this.generatingKey = true

        const payload = {
          name: this.generateApiKeysForm.name,
          role: this.generateApiKeysForm.role,
          resourceServerUrl: null,
          tokenValidityMinutes: this.generateApiKeysForm.expiry,
        }

        sdk.instance
          .apiKeys().generateKey(payload)
          .then(response => {
            this.notifyGood({
              title: this.$t('api-keys.success-generate'),
              message: this.$t('api-keys.key-generated-message', { key: response.clientId }),
            })

            this.generateApiKeysForm.name = ''
            this.generateApiKeysForm.role = ''
            this.generateApiKeysForm.expiry = EXPIRY_DEFAULT
            this.$nextTick(() => {
              this.$refs.generateApiKeysForm.clearValidate()
            })

            this.generatedKey.apiKey = response.clientId
            this.generatedKey.apiSecret = response.clientSecret
            this.keyDialogVisible = true

            this.$root.$emit('reloadApiKeys')
          })
          .catch(error => {
            const errorStr = error.data?.message ?? error
            this.notifyBad({
              title: this.$t('api-keys.failed-generate'),
              message: errorStr,
            })
            if (!error.data?.message) {
              captureSentryError(error)
            }
          })
          .finally(() => {
            this.generatingKey = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item,
  .el-select {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.name-label {
  min-width: 300px;
}

.expiry-label,
.role-label {
  min-width: 200px;
}
</style>
