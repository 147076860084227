<template>
  <el-dialog :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="$t('api-keys.your-api-key')"
    width="60%">
    <p class="mt-0">
      {{ $t('api-keys.key-dialog-description') }}
    </p>

    <el-form label-position="top"
      @submit.native.prevent>
      <el-form-item :label="$t('api-keys.api-key')">
        <div class="flex-row-centered">
          <el-input :value="apiKey"
            readonly="readonly"
            data-name="apiKey"
            data-testid="api-key-input" />
          <el-button data-testid="copy-api-key"
            type="primary"
            plain
            class="ml-1"
            @click="copyToClipboard(apiKey)">
            {{ $t('general.copy') }}
          </el-button>
        </div>
      </el-form-item>

      <el-form-item>
        <template #label>
          <div class="lh-normal mt-2">
            {{ $t('api-keys.secret') }}
            <p class="color-warning mt-0 mb-0-5 font-weight-500">
              {{ $t('api-keys.secret-message') }}
            </p>
          </div>
        </template>
        <div class="flex-row-centered">
          <el-input :value="apiSecret"
            readonly="readonly"
            data-name="apiSecret"
            data-testid="api-secret-input" />
          <el-button data-testid="copy-api-secret"
            type="primary"
            plain
            class="ml-1"
            @click="copyToClipboard(apiSecret)">
            {{ $t('general.copy') }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button data-name="key-panel-close"
        @click="handleClose">
        {{ $t('general.close') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { copyToClipboard } from '@/helpers.js'

export default {
  name: 'KeyAndSecretDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    apiSecret: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard,
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.lh-normal {
  line-height: normal;
}

::v-deep {
  .el-dialog__title {
    font-size: 2.5rem;
  }
  .el-form-item__label {
    font-weight: 500;
  }
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
