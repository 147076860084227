<template>
  <el-dialog :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="$t('api-keys.history')"
    class="api-history-dialog"
    width="75%"
    @opened="loadHistory">
    <p><strong>{{ $t('api-keys.api-key') }}</strong>: {{ apiKey }}</p>

    <el-table v-loading="loadingData"
      :data="historyItems"
      max-height="650">
      <el-table-column prop="name"
        :label="$t('general.name')" />
      <el-table-column prop="role"
        :label="$t('api-keys.role')" />
      <el-table-column prop="user"
        :label="$t('api-keys.edited-by')" />
      <el-table-column prop="date"
        :label="$t('api-keys.update-date')" />
      <el-table-column prop="action"
        :label="$t('api-keys.action')" />
    </el-table>

    <template #footer>
      <el-button data-name="key-panel-close"
        @click="handleClose">
        {{ $t('general.close') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { sanitiseRole } from '@/components/api-keys/ApiKeyUtils.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

const REVISION_CREATE = 'CREATE'
const REVISION_UPDATE = 'UPDATE'

export default {
  name: 'KeyHistoryDialog',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingData: true,
      historyItems: [],
    }
  },

  computed: {
    ...mapGetters('Users', ['getUserByPersonUid']),
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad']),
    /**
     * Modal close handler
     */
    handleClose() {
      this.historyItems = []
      this.$emit('update:visible', false)
    },

    /**
     * Show human readable version of action from history
     * @param {string} revision Action
     */
    humanReadableRevision(revision) {
      switch (revision) {
        case REVISION_CREATE:
          return this.$t('api-keys.created')
        case REVISION_UPDATE:
          return this.$t('api-keys.updated')
        default:
          captureSentryError(new Error(`Key action ${revision} is not recognised`))
          return this.$t('api-keys.unknown-action')
      }
    },

    /**
     * Retrieves the user name by looking them up in the employment data for the company
     * by personUid.
     * @param {string} userUid
     */
    getUserName(userUid) {
      const user = this.getUserByPersonUid(userUid)
      return user ? user.name : this.$t('api-keys.unknown-user')
    },

    /**
     * Get API key history from API
     */
    loadHistory() {
      this.loadingData = true

      sdk.instance.apiKeys().getHistory(this.apiKey)
        .then(results => {
          this.historyItems = results.map(item => {
            const processedItem = {
              name: item.name,
              role: sanitiseRole(this, item.role),
              action: this.humanReadableRevision(item.revisionType),
            }
            if (item.revisionType === REVISION_CREATE) {
              processedItem.user = this.getUserName(item.createdBy)
              processedItem.rawDate = Date.parse(item.createDate)
              processedItem.date = DateTime.fromMillis(processedItem.rawDate).toFormat('LLL dd, y')
            } else {
              processedItem.user = this.getUserName(item.updatedBy)
              processedItem.rawDate = Date.parse(item.lastUpdate)
              processedItem.date = DateTime.fromMillis(processedItem.rawDate).toFormat('LLL dd, y')
            }
            return processedItem
          }).sort((a, b) => {
            return b.rawDate - a.rawDate
          })
        })
        .catch(error => {
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('api-keys.unable-to-read'),
            message: errorStr,
          })
          if (!error.data?.message) {
            captureSentryError(error)
          }
        })
        .finally(() => {
          this.loadingData = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__title {
    font-size: 2.5rem;
  }
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
